module.exports = {
    '/home-page': '/',
    '/home-page-return': '/',
    '/email-marketing-features': '/email-marketing',
    '/price-plans': '/pricing',
    '/start-now/email-lite/signup.jsp': '/signup.jsp', 
    '/start-now/email-lite/account': '/start-now/email-plan/account',
    '/start-now/email-plan/signup.jsp': '/signup.jsp',
    '/start-now/email-plus/signup.jsp': '/signup.jsp',
    '/start-now/plans/signup.jsp': '/signup.jsp',
    '/buynow/start-now/affiliate/signup.jsp': '/buynow/affiliate/signup.jsp'
}
