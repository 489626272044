module.exports = {
    en_US_USD: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-US', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'us',
        countryName: 'United States',
        currency: 'USD',
        currencyWithSymbol: 'USD ($)',
        pathPrefix: '',
    },
    en_AU_AUD: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-AU', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'au',
        countryName: 'Australia',
        currency: 'AUD',
        currencyWithSymbol: 'AUD (A$)',
        pathPrefix: '/au'
    },
    en_CA_CAD: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-CA', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'ca',
        countryName: 'Canada',
        currency: 'CAD',
        currencyWithSymbol: 'CAD (CA$)',
        pathPrefix: '/ca'
    },
    es_CO_USD: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'es-CO', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'es',
        languageName: 'Spanish',
        countryCode: 'co',
        countryName: 'Colombia',
        currency: 'USD', // CTCT-specific requirement - set currency to USD
        currencyWithSymbol: 'USD ($)',
        pathPrefix: '/co'
    },
    en_FR_EUR: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-FR', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'fr',
        countryName: 'France',
        currency: 'EUR',
        currencyWithSymbol: 'EUR (€)',
        pathPrefix: '/fr'
    },
    en_DE_EUR: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-DE', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'de',
        countryName: 'Germany',
        currency: 'EUR',
        currencyWithSymbol: 'EUR (€)',
        pathPrefix: '/de'
    },
    en_IN_INR: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-IN', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'in',
        countryName: 'India',
        currency: 'INR',
        currencyWithSymbol: 'INR (₹)',
        pathPrefix: '/in'
    },
    es_MX_MXN: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'es-MX', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'es',
        languageName: 'Spanish',
        countryCode: 'mx',
        countryName: 'Mexico',
        currency: 'MXN',
        currencyWithSymbol: 'MXN (MX$)',
        pathPrefix: '/mx'
    },
    en_NZ_USD: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-NZ', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'nz',
        countryName: 'New Zealand',
        currency: 'USD', // CTCT-specific requirement - set currency to USD
        currencyWithSymbol: 'USD ($)',
        pathPrefix: '/nz'
    },
    es_ES_EUR: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'es-ES', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'es',
        languageName: 'Spanish',
        countryCode: 'es',
        countryName: 'Spain',
        currency: 'EUR',
        currencyWithSymbol: 'EUR (€)',
        pathPrefix: '/es'
    },
    en_GB_GBP: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-GB', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'gb',
        countryName: 'United Kingdom',
        currency: 'GBP',
        currencyWithSymbol: 'GBP (£)',
        pathPrefix: '/uk'
    },
    en: { // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: '',
        countryName: 'Global',
        currency: 'USD',
        currencyWithSymbol: 'USD ($)',
        pathPrefix: '/global'
    }
}
