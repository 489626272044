/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 import 'whatwg-fetch';
 import Cookies from 'universal-cookie';
 import "core-js";
 import "regenerator-runtime/runtime";
 import {
     getQueryParamVal,
     moveToAnchorId,
     processPathnameLocalization,
     setOptimizelyWebTestLaneCookie,
 } from './src/assets/scripts/helpers';
 import { navigate } from 'gatsby';
 
 if (window.NodeList && !NodeList.prototype.forEach) {
     NodeList.prototype.forEach = Array.prototype.forEach;
 }
 
  /*export const onClientEntry = () => {
     // CTCTFOS team - custom functionality
     setOptimizelyWebTestLaneCookie();
 
     // D1, L1 preview, L1 PR, L1, S1, and prod only
     if (window.location.hostname.includes('.retentionscience.com')) {
         let jqueryJs = appendJqueryJs();
         jqueryJs.addEventListener('load', function callAppendTealiumJs() {
             appendTealiumJS();
         });
         preloadTealiumEndpoint();
     } 
 }*/
 
 /*
     The Gatsby Link component by default will bring you to
     the scrolled position of the page you're navigating to
     the last time youo visited that page. This fix makes it
     so every time you visit a page it shows the top of the
     page. Also "top of the page to ya - hey sha shay" 🍀
 */
 export const onInitialClientRender = () => {
     if (window.location.href.includes('#')) {
         moveToAnchorId(window.location.href)
     } else {
         window.scrollTo(0, 0);
     }
 }
 
 export const onPreRouteUpdate = ({ location, prevLocation }) => {
     const cookies = new Cookies();
     const ctctLocalePrefCookieVal = cookies.get('ctct_locale_pref');
     const ctctGeolocatedCountryCodeCookieVal = cookies.get('ctct-geolocated-country-code');
 
     // pathname localization
     let processPathnameLocalizationObj = processPathnameLocalization(location.pathname, location.search, ctctLocalePrefCookieVal, ctctGeolocatedCountryCodeCookieVal);
     if (processPathnameLocalizationObj && Object.keys(processPathnameLocalizationObj).length > 0) {
         let processPathnameLocalizationObjDestinationHref = processPathnameLocalizationObj.destinationHref;
         if (processPathnameLocalizationObjDestinationHref) {
            navigate(
                processPathnameLocalizationObjDestinationHref,
                { replace: true }
            ); // simulate a redirect on the front end
            return;
         }
     }
 }
 
 /* export const onRouteUpdate = ({ location, prevLocation }) => {
     // for D1, L1, S1, and Prod
     if (window.location.hostname.endsWith('.retentionscience.com')) {
         getCTCTPhoneNumberAndTealiumUDOAndCallUtagView(location, prevLocation);
     }
 } */
 
/*  const getCtctLandingPagesGoSubdomain = () => {
     const incomingHostname = window.location.hostname;
 
     if (incomingHostname.indexOf('.d1.') !== -1) {
         return 'https://go.d1.constantcontact.com';
     } else if (incomingHostname.indexOf('.l1.') !== -1) {
         return 'https://go.l1.constantcontact.com';
     } else if (incomingHostname.indexOf('.s1.') !== -1) {
         return 'https://go.s1.constantcontact.com';
     } else {
         return 'https://go.constantcontact.com';
     }
 } */
 
 // TODO: edit preload, test plan = phone # & tealium & PPC vs. nat search
 /* const preloadTealiumEndpoint = () => {
     let endpoint = getCtctLandingPagesBackEndEndpointUrl('/get/phone-number-and-tealium-udo', window.location, '');
     let link = document.createElement('link');
 
     link.setAttribute('href', endpoint);
     link.setAttribute('rel', 'preload');
     link.setAttribute('as', 'fetch');
     link.setAttribute('crossorigin', 'use-credentials');
 
     document.head.appendChild(link);
 } */
 
 /* const appendTealiumJS = () => {
     const hostname = window.location.hostname;
 
     let tealiumJs = document.createElement('script'),
         tealiumEnv = '';
     if (hostname.includes('.retentionscience.com')) {
         if (hostname.includes('.d1.')) {
             tealiumEnv = 'dev';
         } else if (hostname.includes('.s1.') || hostname.includes('.l1.')) {
             tealiumEnv = 'qa';
         } else {
             tealiumEnv = 'prod';
         }
     }
     tealiumJs.innerHTML = 'window.utag_cfg_ovrd = {noview : true};\n\n(function(a,b,c,d) {\n\ta="https://tags.tiqcdn.com/utag/cc/main/' + tealiumEnv + '/utag.js";\n\tb=document;\n\tc="script";\n\td=b.createElement(c);\n\td.src=a;\n\td.type="text/java"+c;\n\td.async=true;\n\ta=b.getElementsByTagName(c)[0];\n\ta.parentNode.insertBefore(d,a);\n})();';
     document.body.appendChild(tealiumJs);
 } */
 
/*  const appendJqueryJs = () => {
     let jqueryJs = document.createElement('script');
     jqueryJs.src = 'https://static.ctctcdn.com/h/jquery/2.1.4/jquery.min.js';
     document.body.appendChild(jqueryJs);
 
     return jqueryJs;
 } */
 
 /* const getCTCTPhoneNumberAndTealiumUDOAndCallUtagView = (location, prevLocation) => {
     let endpoint = getCtctLandingPagesBackEndEndpointUrl('/get/phone-number-and-tealium-udo', location, prevLocation);
 
     fetch(endpoint, {credentials: 'include'})
         .then(dataWrappedByPromise => dataWrappedByPromise.json())
         .then(data => {
             let ctctPhoneNumberAndTealiumUDOObj = JSON.parse(data);
 
             // CTCT - Entitlement phone #
             let phoneNumber = ctctPhoneNumberAndTealiumUDOObj.entitlementPhoneNumber;
             if (phoneNumber) {
                 let phoneNumberAnchorTags = document.querySelectorAll('.navbar__phone-number');
 
                 for (let i = 0; i < phoneNumberAnchorTags.length; i++) {
                     phoneNumberAnchorTags[i].setAttribute('href', 'tel:' + phoneNumber);
                     phoneNumberAnchorTags[i].textContent = phoneNumber;
                 }
             }
 
             // CTCT - landingPages - Tealium UDO
             let tealiumUDO = ctctPhoneNumberAndTealiumUDOObj.tealiumUDO;
             tealiumUDO.hostName = location.hostname;
             tealiumUDO.pageName = location.pathname;
 
             let referrer = '';
             if (prevLocation) {
                 referrer = prevLocation.origin + prevLocation.pathname;
             } else {
                 referrer = document.referrer;
             }
             tealiumUDO.referrer = referrer;
 
             tealiumUDO.pageViewFired = true;
 
             let firedTealiumUtagViewEvent = false,
             fireTealiumUtagViewEventInterval = setInterval(() => {
                 firedTealiumUtagViewEvent = fireTealiumUtagViewEvent(tealiumUDO, fireTealiumUtagViewEventInterval);
             }, 500);
 
             setTimeout(() => {
                 if (!firedTealiumUtagViewEvent) {
                     console.error('fireTealiumUtagViewEvent - TIMED OUT: Tealium utag.js still not loaded after 60s.');
                     clearInterval(fireTealiumUtagViewEventInterval);
                 }
             }, 60000);
         })
         .catch(error => console.error('Error:', error));
 } */
 
 /* const fireTealiumUtagViewEvent = (tealiumUDO, fireTealiumUtagViewEventInterval) => {
     const utag = window.utag;
 
     try {
         utag.view(tealiumUDO);
 
         clearInterval(fireTealiumUtagViewEventInterval);
         return true;
     } catch (error) {
         window.console.info(`Tealium utag.js not loaded yet: ${error}`);
     }
 } */
 
/* const getCtctLandingPagesBackEndEndpointUrl = (endpointPathname, location, prevLocation) => {
     let endpointWithoutQueryString = getCtctLandingPagesGoSubdomain() + endpointPathname;
 
     let ctctfosReferrerForNatsearchCheck = '';
     if (prevLocation) {
         ctctfosReferrerForNatsearchCheck = prevLocation.origin + prevLocation.pathname;
     } else {
         ctctfosReferrerForNatsearchCheck = document.referrer;
     }
 
     const queryParams = location.search;
 
     // check for NEW incoming CTCT landingPages marketing-specific query params to send to landingPages
     const AID = getQueryParamVal(queryParams, 'AID'), // b/c originally configured as ALL CAPS for affiliates
         cc = getQueryParamVal(queryParams, 'cc'),
         clickid = getQueryParamVal(queryParams, 'clickid'),
         ic = getQueryParamVal(queryParams, 'ic'),
         IRCID = getQueryParamVal(queryParams, 'IRCID'), // b/c originally configured as ALL CAPS for affiliates
         kid = getQueryParamVal(queryParams, 'kid'),
         PID = getQueryParamVal(queryParams, 'PID'), // b/c originally configured as ALL CAPS for affiliates
         pmc = getQueryParamVal(queryParams, 'pmc'),
         pn = getQueryParamVal(queryParams, 'pn'),
         rc = getQueryParamVal(queryParams, 'rc'),
         rmc = getQueryParamVal(queryParams, 'rmc'),
         SID = getQueryParamVal(queryParams, 'SID'); // b/c originally configured as ALL CAPS for affiliates
 
     let ctctLandingPagesBackEndEndpointUrl = endpointWithoutQueryString,
         queryString = '';
 
     if (ctctfosReferrerForNatsearchCheck) {
         queryString = queryString + "&ctctfos_referrer_for_natsearch_check=" + ctctfosReferrerForNatsearchCheck;
     }
 
     if (AID) {
         queryString = queryString + "&AID=" + AID; // b/c originally configured as ALL CAPS for affiliates
     }
 
     if (cc) {
         queryString = queryString + "&cc=" + cc;
     }
 
     if (clickid) {
         queryString = queryString + "&clickid=" + clickid;
     }
 
     if (ic) {
         queryString = queryString + "&ic=" + ic;
     }
 
     if (IRCID) {
         queryString = queryString + "&IRCID=" + IRCID; // b/c originally configured as ALL CAPS for affiliates
     }
 
     if (kid) {
         queryString = queryString + "&kid=" + kid;
     }
 
     if (PID) {
         queryString = queryString + "&PID=" + PID; // b/c originally configured as ALL CAPS for affiliates
     }
 
     if (pn) {
         queryString = queryString + "&pn=" + pn;
     }
 
     if (pmc) {
         queryString = queryString + "&pmc=" + pmc;
     }
 
     if (rc) {
         queryString = queryString + "&rc=" + rc;
     }
 
     if (rmc) {
         queryString = queryString + "&rmc=" + rmc;
     }
 
     if (SID) {
         queryString = queryString + "&SID=" + SID; // b/c originally configured as ALL CAPS for affiliates
     }
 
     // if queryString string is currently at least '&{x}'
     if (queryString.length >= 2 && queryString.startsWith('&')) {
         ctctLandingPagesBackEndEndpointUrl = ctctLandingPagesBackEndEndpointUrl + "?" + queryString.substring(1);
     }
 
     return ctctLandingPagesBackEndEndpointUrl;
 } */
 