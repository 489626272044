export const locations = [
    {
        regionCode: "AL",
        regionDisplay: "Alabama"
    }, {
        regionCode: "AK",
        regionDisplay: "Alaska"
    }, {
        regionCode: "AS",
        regionDisplay: "American Samoa"
    }, {
        regionCode: "AZ",
        regionDisplay: "Arizona"
    }, {
        regionCode: "AR",
        regionDisplay: "Arkansas"
    }, {
        regionCode: "CA",
        regionDisplay: "California"
    }, {
        regionCode: "CO",
        regionDisplay: "Colorado"
    }, {
        regionCode: "CT",
        regionDisplay: "Connecticut"
    }, {
        regionCode: "DE",
        regionDisplay: "Delaware"
    }, {
        regionCode: "DC",
        regionDisplay: "District of Columbia"
    }, {
        regionCode: "FM",
        regionDisplay: "Fed. States of Micronesia"
    }, {
        regionCode: "FL",
        regionDisplay: "Florida"
    }, {
        regionCode: "GA",
        regionDisplay: "Georgia"
    }, {
        regionCode: "GU",
        regionDisplay: "Guam"
    }, {
        regionCode: "HI",
        regionDisplay: "Hawaii"
    }, {
        regionCode: "ID",
        regionDisplay: "Idaho"
    }, {
        regionCode: "IL",
        regionDisplay: "Illinois"
    }, {
        regionCode: "IN",
        regionDisplay: "Indiana"
    }, {
        regionCode: "IA",
        regionDisplay: "Iowa"
    }, {
        regionCode: "KS",
        regionDisplay: "Kansas"
    }, {
        regionCode: "KY",
        regionDisplay: "Kentucky"
    }, {
        regionCode: "LA",
        regionDisplay: "Louisiana"
    }, {
        regionCode: "ME",
        regionDisplay: "Maine"
    }, {
        regionCode: "MH",
        regionDisplay: "Marshall Islands"
    }, {
        regionCode: "MD",
        regionDisplay: "Maryland"
    }, {
        regionCode: "MA",
        regionDisplay: "Massachusetts"
    }, {
        regionCode: "MI",
        regionDisplay: "Michigan"
    }, {
        regionCode: "MN",
        regionDisplay: "Minnesota"
    }, {
        regionCode: "MS",
        regionDisplay: "Mississippi"
    }, {
        regionCode: "MO",
        regionDisplay: "Missouri"
    }, {
        regionCode: "MT",
        regionDisplay: "Montana"
    }, {
        regionCode: "NE",
        regionDisplay: "Nebraska"
    }, {
        regionCode: "NV",
        regionDisplay: "Nevada"
    }, {
        regionCode: "NH",
        regionDisplay: "New Hampshire"
    }, {
        regionCode: "NJ",
        regionDisplay: "New Jersey"
    }, {
        regionCode: "NM",
        regionDisplay: "New Mexico"
    }, {
        regionCode: "NY",
        regionDisplay: "New York"
    }, {
        regionCode: "NC",
        regionDisplay: "North Carolina"
    }, {
        regionCode: "ND",
        regionDisplay: "North Dakota"
    }, {
        regionCode: "MP",
        regionDisplay: "Northern Mariana Is."
    }, {
        regionCode: "OH",
        regionDisplay: "Ohio"
    }, {
        regionCode: "OK",
        regionDisplay: "Oklahoma"
    }, {
        regionCode: "OR",
        regionDisplay: "Oregon"
    }, {
        regionCode: "PW",
        regionDisplay: "Palau"
    }, {
        regionCode: "PA",
        regionDisplay: "Pennsylvania"
    }, {
        regionCode: "PR",
        regionDisplay: "Puerto Rico"
    }, {
        regionCode: "RI",
        regionDisplay: "Rhode Island"
    }, {
        regionCode: "SC",
        regionDisplay: "South Carolina"
    }, {
        regionCode: "SD",
        regionDisplay: "South Dakota"
    }, {
        regionCode: "TN",
        regionDisplay: "Tennessee"
    }, {
        regionCode: "TX",
        regionDisplay: "Texas"
    }, {
        regionCode: "UT",
        regionDisplay: "Utah"
    }, {
        regionCode: "VT",
        regionDisplay: "Vermont"
    }, {
        regionCode: "VA",
        regionDisplay: "Virginia"
    }, {
        regionCode: "VI",
        regionDisplay: "Virgin Islands"
    }, {
        regionCode: "WA",
        regionDisplay: "Washington"
    }, {
        regionCode: "WV",
        regionDisplay: "West Virginia"
    }, {
        regionCode: "WI",
        regionDisplay: "Wisconsin"
    }, {
        regionCode: "WY",
        regionDisplay: "Wyoming"
    }, {
        regionCode: "AB",
        regionDisplay: "Alberta"
    }, {
        regionCode: "BC",
        regionDisplay: "British Columbia"
    }, {
        regionCode: "MB",
        regionDisplay: "Manitoba"
    }, {
        regionCode: "NB",
        regionDisplay: "New Brunswick"
    }, {
        regionCode: "NL",
        regionDisplay: "Newfoundland"
    }, {
        regionCode: "NT",
        regionDisplay: "Northwest Territories"
    }, {
        regionCode: "NS",
        regionDisplay: "Nova Scotia"
    }, {
        regionCode: "ON",
        regionDisplay: "Ontario"
    }, {
        regionCode: "PE",
        regionDisplay: "Prince Edward Island"
    }, {
        regionCode: "QC",
        regionDisplay: "Quebec"
    }, {
        regionCode: "SK",
        regionDisplay: "Saskatchewan"
    }, {
        regionCode: "YT",
        regionDisplay: "Yukon"
    }
];
