import Cookies from 'universal-cookie';
import locales from '../../../config/locales';
import countriesToLocales from '../../../config/countries-to-locales';
import pathPrefixesToLocales from '../../../config/path-prefixes-to-locales';
import trialSiteRedirects from '../../../config/trial-site-redirects';
import ecomSiteRedirects from '../../../config/ecom-site-redirects';
import { locations } from './locations-data';
import Scroll from 'react-scroll';

export const ValidateField = (e, callback) => {

    const field = e.target;

    // Check if the field is required
    if (field.hasAttribute('required')) {

        // TODO: Determine if the selectHiddenOption is being passed to the validator

        // Check if the required field is empty
        if (field.value.length === 0) {
            return callback({
                status: true,
                message: 'Empty fields make me cry 😢'
            });
        } else {
            return callback({
                status: false,
                message: ''
            })
        }
    }

    // Systems nominal
    callback({
        status: false,
        message: ''
    });
}

export const KebabCase = (string) => {
    return string
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('-');
}

export const StateToState = (string) => {
    for(let i = 0; locations.length; i++) {
        if((locations[i].regionCode).toLowerCase() === string) {
            return locations[i].regionDisplay;
        }
    }
};

export function normalizeData(props) {

    Object.keys(props).map(prop => {

        if (props[prop] === undefined) {
            return false;
        }

        const property = props[prop];


        // Hot reloaded props are coming in undefined on save.
        // This prevents the hot reloading from getting borked.
        if (property['en-US'] === undefined) {
            return false;
        }

        // If the props value is an array iterate through each index.
        // If the index value has a "sys" property, first normalize the data
        // and then assign the index value to the prop
        if (Array.isArray(property['en-US'])) {
            const subProps = property['en-US'].map(item => {

                if (item.hasOwnProperty('sys')) {
                    const {
                        fields
                    } = item;

                    normalizeData(fields);

                    return item = fields;
                }

                return false;
            });

            return props[prop] = subProps;

        }

        // If the prop is not an array but has a "sys" property, first normalize
        // the data and then assign the value to the prop
        if (property['en-US'].hasOwnProperty('fields')) {
            const {
                fields
            } = property['en-US'];

            normalizeData(fields);

            return props[prop] = fields;

        } else {
            // Strip the locale from the prop
            return props[prop] = property['en-US'];
        }
    });
}

export function formatContentfullyData(props) {
    Object.keys(props).map(prop => {
        const property = props[prop];

        // Hide the preview pages just in case
        if (prop === 'metaRobots') {
            props[prop] = 'noindex, nofollow';
        }

        if (property === 'html') {
            props.__typename = 'ContentfulHtml';
            props[property] = { html: props[property] };
        }

        if (property && property.hasOwnProperty('_type')) {
            // Add the __typename to align with gatsby query
            const typename = property._type;
            property.__typename = `Contentful${typename.charAt(0).toUpperCase() + typename.slice(1)}`;

            if (property._type === 'image' && property.url !== undefined) {
                property.file = { url: property.url };
            }
        }

        if (prop === 'data') {
            const url = property.url,
                target = property.target,
                title = property.title;

            if (property.linkText && !property.target.fields) {
                property.target = {
                    fields: {
                        url,
                        title,
                        target
                    },
                    sys: {
                        contentful_id: '',
                        id: ''
                    }
                }

                if (property.hasOwnProperty('modal')) {
                    property.target.fields.modal = property.modal;
                    property.target.fields.title = property.title;
                }
            }
        }

        if (Array.isArray(property)) {
            property.map((item, i) => {
                if (item.hasOwnProperty('content') && item._type === 'tinyMceRichTextBlock') {
                    if (property[i].content.internal === undefined) {
                        const tinyContent = property[i].content;
                        property[i].content = {internal: {content: tinyContent}};
                    }
                }

                switch(item._type) {
                    case 'heroNew':
                        item._type = 'Hero';
                        break;
                    case 'mediaBlocks':
                        item._type = 'MediaBlockWrapper';
                        break;
                    case 'textBlock':
                        item._type = 'OneColumnTextBlock';
                        break;
                    case 'tabs':
                        item._type = 'TabsWrapperGeneric';
                        break;
                    default:
                }

                formatContentfullyData(item);

                return false;
            })
        }

        if (property && (property.hasOwnProperty('_id') ||
            property.hasOwnProperty('subContent') ||
            property.hasOwnProperty('content'))) {

            if (property.hasOwnProperty('url')) {
                property.file = { url: property.url };
            }

            formatContentfullyData(property);
        }

        return false;
    });
}

export const getRelativeUrl = (url) => {
    const cookies = new Cookies();
    const ctctSOPSCookieVal = cookies.get('sops');

    if (url) {
        let urlObj = new URL(url, 'https://www.retentionscience.com') // using prod origin as the base (only for the purposes of creating a URL object -- not actually using this base)
        if (urlObj) {
            let pathname = urlObj.pathname,
                search = urlObj.search;

            // SOPS home page destination href, if applicable
            let processHomePageHrefsForSOPSDestinationHref = processHomePageHrefsForSOPS(pathname, search, ctctSOPSCookieVal);
            if (processHomePageHrefsForSOPSDestinationHref) {
                return processHomePageHrefsForSOPSDestinationHref;
            }
        }
    }

    return url;
}

export const getAbsoluteUrl = (url) => {
    /*
        This is where the environment variable is picked up from the
        package.json build scripts. Example: npm run build:l1 makes
        env = 'l1'.

        NOTE: This can NOT be destructured as the read only property
        is only available during run/build time.
    */

    const sandBox = /.(d1|l1|s1)./;

    let env = undefined; // assume in prod

    if (typeof window !== 'undefined') {
        if (sandBox.test(window.location.href)) {
            env = window.location.href.match(sandBox)[1];

            if (env !== undefined) {
                if (url === 'https://login.constantcontact.online/landing/no-auth?goTo=new-site') {
                    if (env === 's1') {
                        url = 'https://login.uat.ctctdev.co/landing/no-auth?goTo=new-site';
                    } else {
                        url = 'https://login.qa.ctctdev.co/landing/no-auth?goTo=new-site';
                    }
                }

                if (url === 'https://login.constantcontact.online/landing/no-auth?goTo=new-store') {
                    if (env === 's1') {
                        url = 'https://login.uat.ctctdev.co/landing/no-auth?goTo=new-store';
                    } else {
                        url = 'https://login.qa.ctctdev.co/landing/no-auth?goTo=new-store';
                    }
                }

                if (url === 'https://login.constantcontact.online/landing/no-auth?goTo=new-logo') {
                    if (env === 's1') {
                        url = 'https://login.uat.ctctdev.co/landing/no-auth?goTo=new-logo'
                    } else {
                        url = 'https://login.qa.ctctdev.co/landing/no-auth?goTo=new-logo'
                    }
                }
            }
        }
    }

    if (url && url.includes('go.constantcontact') && env !== undefined) {
        const protocol = url.includes('http') ? url.split('//') : ['https:', url];
        const path = protocol[1].split('.');
        const goIndex = path.indexOf('go');

        path.splice(goIndex + 1, 0, env);

        return `${protocol[0]}//${path.join('.')}`;
    }

    return url;
}

export const moveToAnchorId = (url) => {

    // check for links with ids in them
    let id = url.split('#')[1];

    // if the id in the url of the button clicked
    // matches an id on the current page, scroll
    // to the corresponding element
    if (document.getElementById(id) !== null) {
        Scroll.scroller.scrollTo(id, {
            duration: 1500,
            smooth: true,
        });
    }
}

export const getOmUrl = (url) => {
    if ((url === undefined) || (url === null)) {
        return '';
    }

    if (url) {
        if (url.includes('.com/')) {
            return ` /${url.split('.com/')[1]}`
        } else {
            return ' ' + url;
        }
    }
}

// adapted from https://css-tricks.com/snippets/javascript/get-url-variables/
export const getQueryParamVal = (queryParams, queryParamName) => {
    let queryParamVal = ''

    if (queryParams && queryParamName) {
        const queryParamsWithoutLeadingQuestionMark = queryParams.substring(1) // remove leading "?" char.
        queryParamsWithoutLeadingQuestionMark.split("&").forEach((element) => {
            if (element) {
                const pair = element.split('=');
                if (pair[0] === queryParamName) {
                    queryParamVal = pair[1]
                }
            }
        });
    }

    return decodeURIComponent(queryParamVal);
}

export const getLocalePathPrefix = (pathname) => {
    let localePathPrefix = ''

    if (pathname) {
        let tokens = pathname.split('/')
        let localePathPrefixCode = '';

        if (tokens.length >= 2) {
            localePathPrefixCode = tokens[1]
        }

        localePathPrefix = "/" + localePathPrefixCode

        if (!pathPrefixesToLocales.hasOwnProperty(localePathPrefix)) {
            localePathPrefix = '' // default to en_US_USD (no locale path prefix) if localePathPrefixCode is nonexistent OR not valid
        }
    }

    return localePathPrefix
}

export const stripLocalePathPrefix = (pathname) => {
    let localePathPrefix = getLocalePathPrefix(pathname)

    if (localePathPrefix) {
        if (pathname === localePathPrefix) {
            pathname = pathname.replace(localePathPrefix, '') // .replace(...), w/o the '/g' modifier, only replaces the first match, which is what we want
        } else {
            pathname = pathname.replace(localePathPrefix + '/', '/') // .replace(...), w/o the '/g' modifier, only replaces the first match, which is what we want
        }
    }

    return pathname;
}

export const getPathnameForNonUSLocale = (pathname, localePathPrefix) => {
    let pathnameForNonUSLocale = ''

    let newLocalePathPrefix = localePathPrefix;

    if ((pathname !== newLocalePathPrefix) && !pathname.startsWith(newLocalePathPrefix + '/')) {
        pathnameForNonUSLocale = newLocalePathPrefix + pathname
    }

    return pathnameForNonUSLocale;
}

export const getPathnameBasedOnLocale = (pathname, locale) => {
    let localeCountryCode = locales[locale].countryCode

    let localePathPrefix = locales[locale].pathPrefix

    let newPathname = "";

    let pathnameWithoutLocalePathPrefix = stripLocalePathPrefix(pathname);

    switch (localeCountryCode) {
        case 'us':
            if (trialSiteRedirects.hasOwnProperty(pathnameWithoutLocalePathPrefix)) {
                pathnameWithoutLocalePathPrefix = trialSiteRedirects[pathnameWithoutLocalePathPrefix];
            }

            if (pathnameWithoutLocalePathPrefix) {
                newPathname = pathnameWithoutLocalePathPrefix;
            } else {
                newPathname = '/'; // special case b/c after a non-US root pathname like /au has its locale path prefix stripped, pathnameWithoutLocalePathPrefix = ''
            }

            break;
        case 'ca':
            let pathnameWithoutLocalePathPrefixForTrialSiteCheck = (pathnameWithoutLocalePathPrefix || '/'); // special case - after a non-US root pathname like /au has its locale path prefix stripped, pathnameWithoutLocalePathPrefix = ''
            if (trialSiteRedirects.hasOwnProperty(pathnameWithoutLocalePathPrefixForTrialSiteCheck)) {
                pathnameWithoutLocalePathPrefix = trialSiteRedirects[pathnameWithoutLocalePathPrefixForTrialSiteCheck];
            }

            newPathname = getPathnameForNonUSLocale(pathnameWithoutLocalePathPrefix, localePathPrefix);
            break;
        default:
            let pathnameWithoutLocalePathPrefixForEcomCheck = (pathnameWithoutLocalePathPrefix || '/'); // special case - after a non-US root pathname like /au has its locale path prefix stripped, pathnameWithoutLocalePathPrefix = ''
            if (ecomSiteRedirects.hasOwnProperty(pathnameWithoutLocalePathPrefixForEcomCheck)) {
                pathnameWithoutLocalePathPrefix = ecomSiteRedirects[pathnameWithoutLocalePathPrefixForEcomCheck];
            }

            newPathname = getPathnameForNonUSLocale(pathnameWithoutLocalePathPrefix, localePathPrefix);
            break;
    }

    return newPathname;
}

export const getCookie = (cname) => {
    const name = cname + "=",
        decodedCookie = decodeURI(document.cookie),
        ca = decodedCookie.split(';');

    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const getCookieDomain = () => {
    const preProdEnvRegex = /.(d1|l1|s1)./;
    let preProdEnv = '';
    if (typeof window !== 'undefined') {
        if (preProdEnvRegex.test(window.location.hostname)) {
            preProdEnv = window.location.hostname.match(preProdEnvRegex)[1];
        }
    }

    let cookieDomain = ''
    if (typeof window !== 'undefined') {
        if (window.location.hostname.endsWith('.retentionscience.com')) {
            if (preProdEnv) {
                cookieDomain = window.location.hostname.substring(window.location.hostname.indexOf(`.${preProdEnv}`));
            } else {
                cookieDomain = '.retentionscience.com'; // prod
            }
        }
    }

    return cookieDomain;
}

export const processFooterLocaleSwitcher = (newLocalePref) => {
    const cookies = new Cookies();
    let updatedCtctLocalePrefCookieVal = '';

    if (locales.hasOwnProperty(newLocalePref)) {
        cookies.set('ctct_locale_pref', newLocalePref, {
            domain: getCookieDomain(),
            maxAge: 15552000,
            path: '/'
        }); // Max-Age = 15552000s = 180 days (matches "ctct_locale_pref" cookie from CTCT landingPages app)

        updatedCtctLocalePrefCookieVal = newLocalePref;
    }

    return updatedCtctLocalePrefCookieVal;
}

export const processPathnameLocalization = (pathname, queryParams, ctctLocalePrefCookieVal, ctctGeolocatedCountryCodeCookieVal) => {
    const pathNameLowercased = (pathname || '').toLowerCase();
    const cookies = new Cookies();

    let currLocale = ''
    let geolocatedCountryCode = ctctGeolocatedCountryCodeCookieVal;
    if (!geolocatedCountryCode) {
        geolocatedCountryCode = 'us'; // default to "us"
    }

    let localeBasedOnGeolocatedCountryCode = countriesToLocales[geolocatedCountryCode]
    if (!localeBasedOnGeolocatedCountryCode) {
        localeBasedOnGeolocatedCountryCode = 'en' // If geolocatedCountryCode is NOT a supported locale, then use the global "en" locale.
    }
    currLocale = ctctLocalePrefCookieVal;

    let newPathname = '';

    if (currLocale) { // user has a locale pref. set in his/her "ctct_locale_pref" cookie
        newPathname = getPathnameBasedOnLocale(pathNameLowercased, currLocale)
    } else { // no locale pref. set
        currLocale = localeBasedOnGeolocatedCountryCode;
        newPathname = getPathnameBasedOnLocale(pathNameLowercased, currLocale)
        cookies.set('ctct_locale_pref', currLocale, {
            domain: getCookieDomain(),
            maxAge: 15552000,
            path: '/'
        }); // Max-Age = 15552000s = 180 days (matches "ctct_locale_pref" cookie from CTCT landingPages app)
    }

    // remove trailing slash from non-root pathname
    if (newPathname !== '/' && newPathname.endsWith('/')) {
        newPathname = newPathname.substring(0, newPathname.length - 1);
    }

    if (newPathname && newPathname !== pathNameLowercased) {
        return { destinationHref: `${newPathname}${queryParams}`, updatedCtctLocalePrefCookieVal: currLocale};
    } else {
        return { destinationHref: '', updatedCtctLocalePrefCookieVal: ''};
    }
}

export const processHomePageHrefsForSOPS = (pathname, queryParams, ctctSOPSCookieVal) => {
    const pathNameLowercased = (pathname || '').toLowerCase();
    let localePrefix = getLocalePathPrefix(pathNameLowercased);
    let pathnameWithoutLocalePathPrefix = stripLocalePathPrefix(pathNameLowercased);
    if (pathnameWithoutLocalePathPrefix === '') {
        pathnameWithoutLocalePathPrefix = '/' // special case - after a non-US root pathname like /au has its locale path prefix stripped, pathnameLowercasedWithoutLocalePathPrefix = ''
    }

    let newPathname = '',
        newHref = '';

    if (pathnameWithoutLocalePathPrefix === '/' || pathnameWithoutLocalePathPrefix === '/home-page') {
        if (ctctSOPSCookieVal) {
            if (ctctSOPSCookieVal.includes('T')) {
                newPathname = localePrefix + '/trial-home'; // maintain locale prefix from original hrefs
            } else if (ctctSOPSCookieVal.includes('O')) {
                newPathname = localePrefix + '/account-home'; // maintain locale prefix from original hrefs
            }
        }
    }

    if (newPathname) {
        newHref = newPathname + queryParams;
    }

    return newHref;
}

export const parseHeadline = headline => {
    const brs = headline.split('<br>');

    return brs.map(br => {
        // find anything that's bold
        const boldText = br.match(/(\*.*)/g);

        if (boldText && boldText.length > 0) {
            return boldText.map(text => {
                const textArr = text.split('*');
                textArr[0] = '<b>';
                textArr[2] = '</b>';
                return textArr.join('');
            });
        }

        return br;
    }).join('\n');
}

export const loadScript = (url, callback) => {
    let script = document.createElement('script'),
        scriptTag = document.querySelector(`script[src="${url}"]`);

    //remove old script before appending new one
    if (scriptTag) {
        scriptTag.parentElement.removeChild(scriptTag);
    }

    if (script.readyState){
        //IE
        script.onreadystatechange = function() {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                if (callback) {
                    callback();
                }

            }
        };
    } else {
        //Others
        script.onload = function() {
            if (callback) {
                callback();
            }
        };
    }

    script.src = url;

    document.getElementsByTagName('head')[0].appendChild(script);
}

// CTCTFOS team - custom functionality
export const setOptimizelyWebTestLaneCookie = () => {
    const cookies = new Cookies();

    if (!cookies.get('test_lane')) {
        cookies.set('test_lane', getRandomNumberForOptimizelyWebTestLaneCookie(1, 100).toString(10), {
            domain: getCookieDomain(),
            maxAge: 315360000,
            path: '/'
        }); // Max-Age = 315360000s = 10 years (user should remain in same test lane for 10 years)
    }
}

export const getRandomNumberForOptimizelyWebTestLaneCookie = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
