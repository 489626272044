module.exports = {
    '/au': 'en_AU_AUD',
    '/ca': 'en_CA_CAD',
    '/co': 'es_CO_USD', // CTCT-specific requirement - set currency to USD
    '/de': 'en_DE_EUR',
    '/es': 'es_ES_EUR',
    '/fr': 'en_FR_EUR',
    '/in': 'en_IN_INR',
    '/mx': 'es_MX_MXN',
    '/nz': 'en_NZ_USD', // CTCT-specific requirement - set currency to USD
    '/uk': 'en_GB_GBP',
    '/global': 'en'
}