module.exports = {
    '/': '/home-page', 
    '/mainpage': '/home-page',
    '/email-marketing': '/email-marketing-features',
    '/email-templates': '/email-marketing-features',
    '/features': '/email-marketing-features',
    '/pricing': '/price-plans',
    '/pricing-plans': '/price-plans',
    '/signup.jsp': '/price-plans',
    '/trial/signup.jsp': '/price-plans',
    '/buynow/affiliate/signup.jsp': '/buynow/start-now/affiliate/signup.jsp', 
    '/buynow/email-plus/signup.jsp"': '/price-plans',
    '/buynow/paid/signup.jsp': '/price-plans',
    '/buynow/plans/signup.jsp': '/price-plans',
    '/buynow/toolkit/signup.jsp': '/price-plans',
    '/home': '/home-page'
}
