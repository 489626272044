module.exports = {
    us: 'en_US_USD',
    au: 'en_AU_AUD',
    ca: 'en_CA_CAD',
    co: 'es_CO_USD', // CTCT-specific requirement - set currency to USD
    de: 'en_DE_EUR',
    es: 'es_ES_EUR',
    fr: 'en_FR_EUR',
    in: 'en_IN_INR',
    gb: 'en_GB_GBP',
    mx: 'es_MX_MXN',
    nz: 'en_NZ_USD' // CTCT-specific requirement - set currency to USD
}